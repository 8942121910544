import { Button, Form, Input, InputProps } from 'antd';
import { FormLabel } from 'components/UI 2/FormItems/FormLabel';
import { useField } from 'formik';

interface Props extends InputProps {
  fieldName: string;
  label?: string;
  handleSubmit?: () => void;
}

export const EmailInput = ({ fieldName, label, handleSubmit, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit?.();
    }
  };

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={value && touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={value && touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Input.Group style={{ display: 'flex' }} compact>
        <Input
          {...rest}
          value={value || undefined}
          allowClear
          onKeyDown={handleEnter}
          onChange={(e): void => setValue(e.target.value)}
          onFocus={(): void => setTouched(false)}
          onBlur={(): void => setTouched(true)}
        />
        <Button disabled={!value} onClick={handleSubmit} type="primary">
          Add
        </Button>
      </Input.Group>
    </Form.Item>
  );
};
