import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Row, Space, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { EquipmentReturnTypePayload } from 'models/EquipmentReturnTypes';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setIsExit } from 'redux/slices/appSlice';

export const CreateTypesControls = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { divisionId } = useParams();

  const { values, submitForm } = useFormikContext<EquipmentReturnTypePayload>();

  const handleBack = (): void => {
    navigate(`/${divisionId}/edit`);
  };

  const handleSave = async (close: boolean): Promise<void> => {
    if (close) {
      dispatch(setIsExit(true));
    }
    await submitForm();
  };
  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" onClick={(): Promise<void> => handleSave(false)}>
        Save
      </Menu.Item>
    </Menu>
  );

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0 }} level={3}>
          Create Equipment Return Type
        </Typography.Title>
      </Col>
      <Col>
        <Space size={1}>
          <Button style={{ marginRight: 8 }} onClick={handleBack}>
            Back to Configuration
          </Button>
          <Dropdown.Button
            disabled={!values.warehouseRoutingPriorities.length}
            style={{ marginRight: 8 }}
            overlay={menuJSX}
            onClick={(): Promise<void> => handleSave(true)}
            type="primary"
            icon={<SaveOutlined />}>
            Save and Exit
          </Dropdown.Button>
          <Tooltip title="Coming soon">
            <Button type="primary" disabled>
              Audit History
            </Button>
          </Tooltip>
        </Space>
      </Col>
    </Row>
  );
};
