import { Card, Col, Form, Space, Typography } from 'antd';
import { toRgba, userFormColors } from 'common/styles/colors';
import { AllowedRuleTypeSelect } from 'components/atoms/AllowedRuleTypeSelect';
import React from 'react';

export const GeneralCard: React.FC = () => {
  // make options for the select from both enums

  /* ******************** Renderer ******************** */
  return (
    <Col span={8} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
      <Card
        style={{ marginBottom: 5, height: '100%' }}
        title={
          <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22, margin: 0 }}>
            General
          </Typography.Title>
        }
        styles={{ body: { padding: 20 }, header: { background: toRgba(userFormColors.royalBlueLight, 0.2) } }}>
        <Form layout="vertical">
          <Space size={14} direction="vertical" style={{ width: '100%' }}>
            <AllowedRuleTypeSelect />
          </Space>
        </Form>
      </Card>
    </Col>
  );
};
