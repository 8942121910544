import { skipToken } from '@reduxjs/toolkit/dist/query';
import { List, Space } from 'antd';
import RecyclingBinCard from 'components/atoms/RecyclingBinCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { RecycleControls } from 'components/organisms/RecycleControls';
import { EquipmentReturnType } from 'models/EquipmentReturnTypes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { ReduxState } from 'redux/store';

export const TypesRecycleBinPage = (): JSX.Element => {
  const { divisionId } = useParams();
  const { equipmentReturnTypesParams } = useSelector((state: ReduxState) => state);

  const { data, isLoading, isFetching } = useGetEquipmentReturnConfigurationTypesQuery(divisionId ? { divisionId, params: { ...equipmentReturnTypesParams, returnDeletedDataOnly: true } } : skipToken);

  if (isLoading) return <LoaderWithMessage loadingMessage="Loading recycle bin" />;

  return (
    <Space direction="vertical">
      <RecycleControls />
      <List
        loading={{ indicator: <LoaderWithMessage />, spinning: isFetching }}
        dataSource={data?.data}
        grid={{ gutter: [5, 5] as any, column: 4 }}
        renderItem={(item: EquipmentReturnType): React.ReactNode => <RecyclingBinCard type={item} />}
      />
    </Space>
  );
};
