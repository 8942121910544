import { Card, Form, Space, Typography } from 'antd';
import { SwitchInputCard } from 'components/UI 2/FormItems/SwitchInputCard';
import React from 'react';

export const AddressesCard: React.FC = () => {
  /* ******************** Renderer ******************** */
  return (
    <Card
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22, margin: 0 }}>
          Addresses
        </Typography.Title>
      }
      styles={{ body: { padding: 20 }, header: { background: '#FFEAC1' } }}>
      <Form layout="vertical">
        <Space size={14} direction="vertical" style={{ width: '100%' }}>
          <SwitchInputCard fieldName="allowManualAddressEntry" label="Allow Manual Address Entry" />
          <SwitchInputCard fieldName="allowManualAddressToPostOffice" label="Add Manual Addresses to Post Office" />
          <SwitchInputCard fieldName="displayAddressesByDefault" label="Display Addresses by Default" />
        </Space>
      </Form>
    </Card>
  );
};
