import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIncludeInactiveData, setReturnInactiveDataOnly } from 'redux/services/maurice/requestTypeParams';

export const IsActiveFilter = (): JSX.Element => {
  const dispatch = useDispatch();
  const handleFilter = (e: RadioChangeEvent): void => {
    switch (e.target.value) {
      case 'all':
        dispatch(setIncludeInactiveData(true));
        dispatch(setReturnInactiveDataOnly(false));

        break;

      case 'active':
        dispatch(setIncludeInactiveData(false));
        dispatch(setReturnInactiveDataOnly(false));
        break;

      case 'inactive':
        dispatch(setReturnInactiveDataOnly(true));

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setIncludeInactiveData(undefined));
      dispatch(setReturnInactiveDataOnly(undefined));
      dispatch(setIncludeInactiveData(true));
    };
  }, []);

  return (
    <Radio.Group buttonStyle="solid" onChange={handleFilter} defaultValue={'all'}>
      <Radio.Button value="all">All Data</Radio.Button>
      <Radio.Button value="active">Active</Radio.Button>
      <Radio.Button value="inactive">Inactive</Radio.Button>
    </Radio.Group>
  );
};
