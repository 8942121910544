import { HolderOutlined, PlusCircleOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Card, Col, message, Modal, Row, Table, Tooltip, Typography } from 'antd';
import { toRgba, userFormColors } from 'common/styles/colors';
import { LottieButton } from 'components/atoms/LottieButton';
import { LottieBlock } from 'components/common/LottieBlock';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import update from 'immutability-helper';
import _ from 'lodash';
import { EquipmentReturnTypePayload, warehousePrioritySchema, WarehouseRoutingPriority } from 'models/EquipmentReturnTypes';
import { useCallback, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { manager } from 'utils/dndUtils';
import cloneLottie from '../../assets/clone.json';
import editLottie from '../../assets/ed.json';
import trashLottie from '../../assets/trash.json';
import addLottie from '../../assets/ware.json';
import { WarehouseRoutingPriorityForm } from './WarehousePriorityForm';

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

export const WarehouseRoutingPriorityTable: React.FC = (): JSX.Element => {
  const [{ value }, , { setValue }] = useField<WarehouseRoutingPriority[]>('warehouseRoutingPriorities');
  const [index, setIndex] = useState<number>(0);
  const [{ value: record }, , { setValue: setRecord }] = useField<WarehouseRoutingPriority>(`warehouseRoutingPriorities[${index}]`);
  const { values: warehouseRoutingRules, setValues } = useFormikContext<EquipmentReturnTypePayload>();

  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean | undefined>(false);
  const [isClone, setIsClone] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();

  const type = 'DraggableBodyRow';

  const commonStockFormik = useFormik<WarehouseRoutingPriority>({
    enableReinitialize: true,
    validationSchema: warehousePrioritySchema,
    initialValues: {
      priority: value?.length ? value.length + 1 : 1,
      countryCode: '',
      subdivisionCode: '',
      postalCode: '',
      warehouseId: ''
    },
    onSubmit: (values) => {
      if (warehouseRoutingRules.warehouseRoutingPriorities?.find((rule) => rule === values)) {
        message.error('Rule already exists, no duplicates allowed');

        return;
      }
      setValue([...value, values]);
      setIsOpen(false);
      commonStockFormik.resetForm();
    }
  });
  const editCommonStockFormik = useFormik<WarehouseRoutingPriority>({
    enableReinitialize: true,
    initialValues: {
      priority: record ? record.priority : value?.length ? value.length + 1 : 1,
      countryCode: record ? record.countryCode : '',
      subdivisionCode: record ? record.subdivisionCode : '',
      postalCode: record ? record.postalCode : '',
      warehouseId: record ? record.warehouseId : ''
    },
    onSubmit: (values) => {
      if (warehouseRoutingRules.warehouseRoutingPriorities?.find((rule) => rule === values)) {
        message.error('Rule already exists, no duplicates allowed');

        return;
      }
      if (isClone) {
        setValues((prev) => ({
          ...prev,
          warehouseRoutingPriorities: [...value, values].map((row, i) => ({
            ...row,
            priority: i + 1
          }))
        }));
        setIsEditOpen(false);
        editCommonStockFormik.resetForm();
        setIsClone(false);

        return;
      }
      setRecord(values);
      setIsEditOpen(false);
      editCommonStockFormik.resetForm();
    }
  });

  const handleSubmit = (): void => {
    commonStockFormik.submitForm();
  };

  const handleCancel = (): void => {
    setIsOpen(false);
    setIsEditOpen(false);
    commonStockFormik.resetForm();
    editCommonStockFormik.resetForm();
    setIsClone(false);
  };

  const handleDeleteRow = (record: WarehouseRoutingPriority): void => {
    if (!warehouseRoutingRules.warehouseRoutingPriorities) return;

    setValues((prev) => ({
      ...prev,
      warehouseRoutingPriorities: warehouseRoutingRules.warehouseRoutingPriorities
        ? warehouseRoutingRules.warehouseRoutingPriorities.filter((item) => item !== record).map((item, i) => ({ ...item, priority: i + 1 }))
        : []
    }));
  };

  const handleEditRow = (_: WarehouseRoutingPriority, index: number): void => {
    setIndex(index);
    setIsEditOpen(true);
  };
  const handleClone = (_: WarehouseRoutingPriority, index: number): void => {
    setIndex(index);
    setIsClone(true);
    setIsEditOpen(true);
  };

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps): JSX.Element => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    drop(drag(ref));

    return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />;
  };

  const cols = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <HolderOutlined style={{ cursor: 'grab' }} />
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 10
    },
    {
      title: 'Country',
      dataIndex: 'countryCode',
      key: 'countryCode'
    },
    {
      title: 'State/Province',
      dataIndex: 'subdivisionCode',
      key: 'subdivisionCode'
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      key: 'postalCode'
    },
    {
      title: 'Warehouse ID',
      dataIndex: 'warehouseId',
      key: 'warehouseId'
    },

    {
      title: 'Actions',
      width: 175,
      align: 'center' as any,
      render: (_: string, record: WarehouseRoutingPriority, i: number) => (
        <Row justify="center" gutter={[5, 1]}>
          <Col>
            <Tooltip title="Clone Rule">
              <Button onClick={(): void => handleClone(record, i)} icon={<LottieButton animationData={cloneLottie} lottieStyle={{ width: 25, height: 25 }} />} />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Edit Rule">
              <Button onClick={(): void => handleEditRow(record, i)} icon={<LottieButton animationData={editLottie} lottieStyle={{ width: 25, height: 25 }} />} />
            </Tooltip>
          </Col>
          <Col>
            {contextHolder}
            <Tooltip title="Delete Rule">
              <Button
                onClick={() =>
                  modal.confirm({
                    title: 'IF YOU CONTINUE, THE CHANGES WILL NOT GO INTO EFFECT UNTIL YOU CLICK SAVE.',
                    icon: <WarningFilled style={{ color: 'red' }} />,
                    okButtonProps: { style: { background: 'red', border: 'none' } },
                    okText: 'Delete',
                    onOk: () => handleDeleteRow(record),
                    prefixCls: 'ant-modal'
                  })
                }
                icon={<LottieButton animationData={trashLottie} lottieStyle={{ width: 25, height: 25 }} />}
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!warehouseRoutingRules.warehouseRoutingPriorities) return;
      const dragRow = value[dragIndex];

      setValue(
        update(warehouseRoutingRules.warehouseRoutingPriorities, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }).map((d, i) => ({ ...d, priority: i + 1, key: i }))
      );
    },
    [warehouseRoutingRules, value]
  );

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  return (
    <Card
      title={
        <Row align="middle">
          <Typography.Title level={4} style={{ fontWeight: 400, padding: 0, margin: 0, marginRight: 5 }}>
            Warehouse Routing Priorities
          </Typography.Title>
        </Row>
      }
      styles={{ header: { background: toRgba(userFormColors.raisinBlackDark, 0.5) } }}
      style={{ height: '100%' }}
      extra={
        <Button onClick={(): void => setIsOpen(true)} icon={<PlusCircleOutlined />}>
          Add Priority
        </Button>
      }>
      <FormikProvider value={commonStockFormik}>
        <Modal width={400} onCancel={handleCancel} closable={false} open={isOpen} onOk={(): Promise<void> => commonStockFormik.submitForm()}>
          <WarehouseRoutingPriorityForm title="Add Warehouse Routing Priority" />
        </Modal>
      </FormikProvider>
      <FormikProvider value={editCommonStockFormik}>
        <Modal
          closable={false}
          okButtonProps={{ disabled: _.isEqual(editCommonStockFormik.initialValues, editCommonStockFormik.values) }}
          width={400}
          onCancel={handleCancel}
          onOk={(): Promise<void> => editCommonStockFormik.submitForm()}
          open={isEditOpen}>
          <WarehouseRoutingPriorityForm title={!isClone ? 'Edit Warehouse Routing Priority' : 'Add Warehouse Routing Priority'} />
        </Modal>
      </FormikProvider>
      <DndProvider manager={manager} backend={HTML5Backend}>
        <Table
          locale={{ emptyText: <LottieBlock title="Add warehouse routing priority to continue" lottieFile={addLottie} style={{ height: 200, width: 350 }} /> }}
          columns={cols}
          pagination={false}
          dataSource={warehouseRoutingRules.warehouseRoutingPriorities}
          components={components}
          rowKey={(record): number => record?.priority ?? 0}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow
            };

            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>
    </Card>
  );
};
