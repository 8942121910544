import { DeleteOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import { toRgba, userFormColors } from 'common/styles/colors';
import { enumMapper } from 'components/atoms/AllowedRuleTypeSelect';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import EllipsesText from 'components/atoms/EllipsiesText';
import { NameContainsFilter } from 'components/atoms/Filters/NameContainsFilter';
import { IsActiveFilter } from 'components/atoms/IsActiveFilter';
import { EquipmentReturnConfiguration } from 'models/EquipmentReturnConfig';
import { EquipmentReturnType } from 'models/EquipmentReturnTypes';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { LegacyRef, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { ReduxState } from 'redux/store';

interface Props {
  config?: EquipmentReturnConfiguration;
}

const styles: InlineStylesModel = {
  card: {
    backgroundColor: 'white',
    borderRadius: 5
  },
  categoryId: {
    color: 'grey',
    lineHeight: '.7',
    paddingBottom: '13px'
  },
  manufacturer: {
    fontWeight: 600
  },
  dispositionRuleType: {
    color: 'grey',
    height: '15px'
  },
  partDescription: {
    color: 'grey'
  },
  itemName: {
    fontWeight: 600,
    fontSize: 20,
    color: '#203175'
  },
  salesOrderType: {
    color: 'grey',
    textAlign: 'right'
  },
  robotPadding: {
    paddingTop: 5,
    cursor: 'pointer'
  },
  smallGrouping: {
    color: 'grey',
    fontSize: '8',
    background: '#8383831a',
    padding: 4,
    borderRadius: 100
  },
  view: {
    color: '#203175',
    fontWeight: 600,
    fontSize: '15px',
    cursor: 'pointer'
  },
  test: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};

export const SparesRequestTypesList: React.FC<Props> = ({ config }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { divisionId } = useParams();

  const [showRequestTypes, setShowRequestTypes] = useState(false);

  const { equipmentReturnTypesParams } = useSelector((state: ReduxState) => state);
  const { data: testTypes, isLoading, isFetching } = useGetEquipmentReturnConfigurationTypesQuery(divisionId ? { divisionId, params: equipmentReturnTypesParams } : skipToken);

  const handleNavigate = (item: EquipmentReturnType): void => {
    navigate(`/${divisionId}/types/${item.id}/edit`);
  };

  const handleCreateTypeNavigate = (): void => {
    navigate(`/${divisionId}/types/create`);
  };

  const handleRecycleBinNavigate = (): void => {
    navigate(`/${divisionId}/types/recycle-bin`);
  };

  const handleToggleShowRequestTypes = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowRequestTypes((prevState) => !prevState);
  };

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showRequestTypes ? height : 0
  });

  if (isLoading) return <BlockLoader direction="loader loader--slideUp" />;

  const businessListJSX: JSX.Element = (
    <Row>
      <Col span={24}>
        <Row gutter={8} justify="space-between" style={{ marginTop: 8, background: 'white', padding: 20 }}>
          <Row gutter={8}>
            <Col>
              <NameContainsFilter />
            </Col>
            <Col>
              <IsActiveFilter />
            </Col>
          </Row>
          <Col>
            <Tooltip title={!config ? 'You must save and create the equipment return configuration first' : ''}>
              <Button disabled={!config} onClick={handleCreateTypeNavigate}>
                Add Type
              </Button>
            </Tooltip>
            <Button type="primary" style={{ marginLeft: 5 }} icon={<DeleteOutlined />} onClick={handleRecycleBinNavigate}>
              Recycle-Bin
            </Button>
          </Col>
        </Row>
      </Col>
      <Divider style={{ margin: '8px 0' }} />
      <Col span={24}>
        <List
          dataSource={testTypes?.data}
          loading={{ indicator: <BlockLoader direction="loader loader--slideUp" />, spinning: isLoading || isFetching }}
          grid={{ gutter: 4, column: 4, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
          style={{ width: '100%' }}
          renderItem={(item: EquipmentReturnType): React.ReactNode => (
            <Col>
              <Card hoverable style={styles.card} onClick={(): void => handleNavigate(item)}>
                <Row gutter={[2, 0]} justify="space-between" align="middle">
                  <Col style={styles.itemName} span={20}>
                    <EllipsesText tooltipCharacterCount={23} text={item.name} />
                  </Col>
                  <Tooltip title="Carrier">
                    <Col style={styles.salesOrderType} span={4}>
                      <EllipsesText tooltipCharacterCount={23} text={item.carrierName?.toUpperCase() ?? ''} color="gray" />
                    </Col>
                  </Tooltip>
                </Row>
                <Row justify="space-between" align="top"></Row>
                {/* <Row style={styles.dispositionRuleType}>{item.dispositionRuleType !== '' ? item.name : 'N/A'}</Row> */}
                <Row justify="center">
                  <Col span={24} style={{ marginBottom: 10 }}>
                    <Divider />
                  </Col>
                </Row>
                <Row justify="space-between" align="bottom">
                  <Col>
                    <Row style={styles.smallGrouping} gutter={16} align="middle">
                      <Col>{enumMapper(item.equipmentReturnRuleTypeName)}</Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        />
      </Col>
    </Row>
  );

  return (
    <Col span={24} style={{ marginBottom: showRequestTypes ? 10 : 0 }}>
      <Row onClick={handleToggleShowRequestTypes} style={{ padding: 15, background: toRgba(userFormColors.orangeWeb, 0.4), borderRadius: 5, cursor: 'pointer' }} justify="space-between">
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0, fontSize: 22 }} level={3}>
          Equipment Return Types
        </Typography.Title>
        <Button style={{ width: 115 }} type="text" onClick={handleToggleShowRequestTypes} key="show-divisions" icon={<CaretSpinIcon isOpen={showRequestTypes} />}>
          {showRequestTypes ? 'Collapse' : 'Expand'}
        </Button>
      </Row>
      <Row style={{ background: 'transparent', padding: '0 1.25px' }}>
        <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden', width: '100%' }, ...expandAnimation }}>
          {showRequestTypes && (
            <div ref={ref as LegacyRef<HTMLDivElement>} style={{ width: '100%' }}>
              {businessListJSX}
            </div>
          )}
        </animated.div>
      </Row>
    </Col>
  );
};
