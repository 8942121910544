import { Button, Col, Divider, Row } from 'antd';
import { NameContainsFilter } from 'components/atoms/Filters/NameContainsFilter';
import RecoverActions from 'components/molecules/RecoverActions';
import { AntPageTitle } from 'components/UI 2/AntPageTitle';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const RecycleControls: React.FC = () => {
  const navigate = useNavigate();
  const { divisionId } = useParams();

  return (
    <>
      <Row justify="space-between">
        <AntPageTitle text="Configuration Types - Recycle Bin" />
        <Button style={{ marginRight: 5 }} onClick={(): void => navigate(`/${divisionId}/edit`)}>
          Back to Configuration
        </Button>
      </Row>
      <Row gutter={[16, 16]} style={{ background: 'white', padding: '20px 0px', marginTop: 15 }}>
        <Col>
          <NameContainsFilter />
        </Col>
      </Row>
      <RecoverActions />
      <Divider style={{ marginTop: 10 }} />
    </>
  );
};
