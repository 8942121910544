import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';

export const EditPageControls = (): JSX.Element => {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
      <Col>
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0 }} level={3}>
          Equipment Returns Configuration
        </Typography.Title>
      </Col>
      <Col>
        <Space size={5}>
          <Tooltip title="Coming soon">
            <Button disabled style={{ marginRight: 5 }}>
              Audit History
            </Button>
          </Tooltip>
          <Button type="primary" loading={isSubmitting} onClick={submitForm}>
            Save
          </Button>
        </Space>
      </Col>
    </Row>
  );
};
