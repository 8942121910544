import { message, Row, Select, Spin } from 'antd';
import { MessageType } from 'antd/es/message/interface';
import { FormLabel } from 'components/UI 2/FormItems/FormLabel';
import { Field, FieldProps } from 'formik';
import { rest } from 'lodash';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { SizedLoader } from './SizedLoader';

export enum EquipmentReturnLabels {
  EquipmentPickup = 'Equipment Pickup',
  Decommission = 'Decommission',
  ReturnShipment = 'Return Shipment / Create Waybill'
}

export enum EquipmentReturnTypeValues {
  EquipmentPickup = 'EquipmentPickup',
  Decommission = 'Decommission',
  ReturnShipment = 'ReturnShipment'
}

// make a mapper when that takes in a string (value) and returns the label but the labels are different than the values

export const enumMapper = (value: string): string => {
  switch (value) {
    case EquipmentReturnTypeValues.EquipmentPickup:
      return EquipmentReturnLabels.EquipmentPickup;

    case EquipmentReturnTypeValues.Decommission:
      return EquipmentReturnLabels.Decommission;

    case EquipmentReturnTypeValues.ReturnShipment:
      return EquipmentReturnLabels.ReturnShipment;

    default:
      return '';
  }
};

export const AllowedRuleTypeSelect: React.FC = () => {
  const { divisionId } = useParams();
  const { data, isLoading } = useGetEquipmentReturnConfigurationTypesQuery({ divisionId: divisionId as string, params: {} }, { skip: !divisionId });
  const options = Object.entries(EquipmentReturnLabels).map(([key, label]) => ({
    label,
    value: EquipmentReturnTypeValues[key as keyof typeof EquipmentReturnTypeValues]
  }));

  const typesInUse = data?.data.map((type) => type.equipmentReturnRuleTypeName);

  return (
    <Field name={'allowedEquipmentReturnTypes'}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        const handleChange = (val: string[]): void | MessageType => {
          return;
        };

        return (
          <Spin spinning={isLoading} indicator={<SizedLoader small />}>
            <Row style={{ width: '100%' }}>
              <FormLabel label="Allowed Equipment Return Types" />

              <Select
                {...field}
                showSearch
                allowClear
                onClear={(): void => {
                  setFieldTouched('allowedEquipmentReturnTypes', true);
                }}
                filterOption={(input, option): boolean => {
                  const label = typeof option?.label === 'string' ? option.label.toLowerCase() : '';

                  return label.toLowerCase().includes(input.toLowerCase()) ?? false;
                }}
                onChange={handleChange}
                onSelect={(e) => {
                  setFieldValue('allowedEquipmentReturnTypes', [...field.value, e]);
                }}
                onDeselect={(val, opt): void | MessageType => {
                  if (typesInUse?.includes(val)) {
                    return message.error(`Cannot remove ${enumMapper(val)} because it is in use`);
                  }
                  setFieldValue(
                    'allowedEquipmentReturnTypes',
                    field.value.filter((v: string) => v !== val)
                  );
                }}
                status={meta.error && meta.touched ? 'error' : undefined}
                onFocus={(): void => setFieldTouched('allowedEquipmentReturnTypes', false)}
                onBlur={(): void => setFieldTouched('allowedEquipmentReturnTypes', true)}
                style={{ width: '100%' }}
                options={options}
                mode="multiple"
                {...rest}
              />
              <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
            </Row>
          </Spin>
        );
      }}
    </Field>
  );
};
