import { About } from 'pages/About';
import { CreateTypesPage } from 'pages/create/CreateTypesPage';
import { EditPage } from 'pages/edit/EditPage';
import { EditTypesPage } from 'pages/edit/EditTypesPage';
import { EditProfilePage } from 'pages/EditProfile';
import { Home } from 'pages/home/Home';
import { TypesRecycleBinPage } from 'pages/recycle/TypesRecycleBin';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/release-notes/:versionNumber" element={<ReleaseNotesPage />} />
      <Route path="/:divisionId/edit" element={<EditPage />} />
      <Route path="/:divisionId/types/create" element={<CreateTypesPage />} />
      <Route path="/:divisionId/types/recycle-bin" element={<TypesRecycleBinPage />} />
      <Route path="/:divisionId/types/:typeId/edit" element={<EditTypesPage />} />
      <Route path="/profile/" element={<EditProfilePage />} />
    </Routes>
  );
};
