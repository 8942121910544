import { Space, Typography } from 'antd';
import { FC } from 'react';
import Lottie, { LottieProps } from 'react-lottie-player';

type Props = LottieProps & {
  lottieFile: object;
  title: string;
};

export const LottieBlock: FC<Props> = ({ lottieFile, title, ...rest }) => {
  return (
    <Space align="center" direction="vertical" size={0}>
      <Lottie loop animationData={lottieFile} {...rest} play />
      <Typography.Text style={{ fontSize: 17 }}>{title}</Typography.Text>
    </Space>
  );
};
