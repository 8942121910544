import { Card, Space, Spin, Typography } from 'antd';
import { toRgba, userFormColors } from 'common/styles/colors';
import { FieldInput } from 'components/UI 2/FormItems/FieldInput';
import { FieldInputSelect } from 'components/UI 2/FormItems/FieldInputSelect';
import { Country, State } from 'country-state-city';
import { useFormikContext } from 'formik';
import { WarehouseRoutingPriority } from 'models/EquipmentReturnTypes';
import { useGetWarehousesQuery } from 'redux/services/kingLivingston/useKingstonLivingstonService';

export type Props = {
  title: string;
};

// eslint-disable-next-line react/prop-types
export const WarehouseRoutingPriorityForm: React.FC<Props> = ({ title }) => {
  const { data, isLoading, isFetching } = useGetWarehousesQuery({ dataAreaId: 'MDSI', overrideSkipTake: true });
  const { values } = useFormikContext<WarehouseRoutingPriority>();
  const countries = Country.getAllCountries();
  const countryOptions = countries.map((country) => ({ label: country.name, value: country.isoCode }));
  const states = values.countryCode ? State.getStatesOfCountry(values.countryCode) : [];
  const stateOptions = states.map((state) => ({ label: state.name, value: state.isoCode }));
  const warehouseOptions = data?.data.map((warehouse) => ({ label: warehouse.warehouseId, value: warehouse.warehouseId }));

  return (
    <Card
      title={
        <Typography.Title level={5} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      }
      styles={{ body: { padding: 20 }, header: { background: toRgba(userFormColors.redSalsa, 0.3) } }}>
      <Space direction="vertical" size={15} style={{ width: '100%' }}>
        <FieldInputSelect options={countryOptions} fieldName="countryCode" label="Country" />
        <FieldInputSelect disabled={!values.countryCode} options={stateOptions} fieldName="subdivisionCode" label="State/Province" />
        <FieldInput fieldName="postalCode" label="Postal Code" />
        <Spin spinning={isFetching || isLoading}>
          <FieldInputSelect options={warehouseOptions} fieldName="warehouseId" label="Warehouse" />
        </Spin>
      </Space>
    </Card>
  );
};
