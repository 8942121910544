import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, List, Row, Space, Typography } from 'antd';
import { toRgba, userFormColors } from 'common/styles/colors';
import { EmailInput } from 'components/atoms/EmailInput';
import { LottieButton } from 'components/atoms/LottieButton';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { EquipmentReturnConfigurationToUpsert } from 'models/EquipmentReturnConfig';
import React from 'react';
import * as Yup from 'yup';
import trashLottie from '../../assets/trash.json';

export const DispatcherNotificationsCard: React.FC = () => {
  const { values: formVals, setValues } = useFormikContext<EquipmentReturnConfigurationToUpsert>();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required')
      .test('isUnique', 'Email already exists', (value) => {
        return !formVals.dispatcherNotifications.includes(value || '');
      })
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      email: ''
    },
    onSubmit: async (values) => {
      setValues({ ...formVals, dispatcherNotifications: [...formVals.dispatcherNotifications, values.email] });
      formik.resetForm();
    }
  });

  const handleShipmentEmailDelete = (email: string): void => {
    setValues({ ...formVals, dispatcherNotifications: formVals.dispatcherNotifications.filter((e) => e !== email) });
  };

  /* ******************** Renderer ******************** */
  return (
    <Col span={8} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
      <Card
        style={{ marginBottom: 5, height: '100%' }}
        title={
          <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22, margin: 0 }}>
            Dispatcher Notifications
          </Typography.Title>
        }
        styles={{ body: { padding: 20 }, header: { background: toRgba(userFormColors.illuminatingEmerald, 0.8) } }}>
        <Form layout="vertical">
          <Space size={14} direction="vertical" style={{ width: '100%' }}>
            <Form layout="vertical" style={{ width: '100%' }}>
              <FormikProvider value={formik}>
                <Row>
                  <Col span={24} style={{ marginTop: 12 }}>
                    <EmailInput handleSubmit={() => formik.submitForm()} label="Email" type="email" fieldName="email" />
                  </Col>
                </Row>
              </FormikProvider>
            </Form>
            <List
              grid={{ column: 1 }}
              locale={{ emptyText: <></> }}
              dataSource={formVals.dispatcherNotifications}
              renderItem={(item): React.ReactNode => (
                <Card style={{ width: '100%', borderRadius: 5, marginBottom: 5 }} bodyStyle={{ padding: '12px 18px' }}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <MailOutlined style={{ fontSize: 16 }} />
                      <Typography.Text style={{ textAlign: 'center', marginLeft: 8, fontSize: 16 }}>{item}</Typography.Text>
                    </Col>
                    <Button
                      onClick={(): void => handleShipmentEmailDelete(item)}
                      icon={<LottieButton animationData={trashLottie} />}
                      style={{ border: 'none', background: '#0000001a', borderRadius: '50%', width: 20, height: 20 }}
                    />
                  </Row>
                </Card>
              )}
            />
          </Space>
        </Form>
      </Card>
    </Col>
  );
};
