import { skipToken } from '@reduxjs/toolkit/dist/query';
import { message, Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { EditPageControls } from 'components/molecules/EditPageControls';
import { EditPageCards } from 'components/organisms/EditPageCards';
import { FormikProvider, useFormik } from 'formik';
import { EquipmentReturnConfigurationToUpsert } from 'models/EquipmentReturnConfig';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnConfigurationQuery, useUpdateEquipmentReturnConfigurationMutation } from 'redux/services/maurice/equipmentReturnConfig';

export const EditPage = (): JSX.Element => {
  const { divisionId } = useParams();

  const { data: test, isLoading, isFetching } = useGetEquipmentReturnConfigurationQuery(divisionId ?? skipToken);

  const [triggerUpdate, { isLoading: isUpdating }] = useUpdateEquipmentReturnConfigurationMutation();

  const formik = useFormik<EquipmentReturnConfigurationToUpsert>({
    enableReinitialize: true,
    initialValues: {
      allowedEquipmentReturnTypes: test?.allowedEquipmentReturnTypes ?? [],
      dispatcherNotifications: test?.dispatcherNotifications ?? []
    },

    onSubmit: async (values) => {
      try {
        if (!divisionId) {
          message.error('Division cannot be found');

          return;
        }
        const updatedDated = await triggerUpdate({ divisionId, payload: values }).unwrap();

        message.success(`Successfully updated ${updatedDated.division.name} - ${updatedDated.division.erpId} configuration`);
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage ?? 'Could not be updated at this time');
      }
    }
  });

  if (isLoading || isFetching) return <LoaderWithMessage loadingMessage="Loading configuration" />;

  return (
    <FormikProvider value={formik}>
      <Space size={6} direction="vertical">
        <Spin spinning={isUpdating} indicator={<BlockLoader message="Updating configuration" direction="loader loader--slideUp" />}>
          <EditPageControls />
          <EditPageCards config={test} />
        </Spin>
      </Space>
    </FormikProvider>
  );
};
