import { Card, Space, Typography } from 'antd';
import { FieldInput } from 'components/UI 2/FormItems/FieldInput';
import React from 'react';

export const ShipmentsCard: React.FC = () => {
  /* ******************** Renderer ******************** */
  return (
    <Card
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22, margin: 0 }}>
          Carrier Information
        </Typography.Title>
      }
      styles={{ header: { background: '#18a79984' }, body: { padding: 20 } }}
      style={{ height: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }} size={20}>
        <FieldInput fieldName="carrierName" label="Carrier" />
        <FieldInput fieldName="carrierAccountNumber" label="Account Number" />
      </Space>
    </Card>
  );
};
