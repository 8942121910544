import { Row } from 'antd';
import { DispatcherNotificationsCard } from 'components/molecules/DispatcherNotificationsCard';
import { GeneralCard } from 'components/molecules/GeneralCard';
import { EquipmentReturnConfiguration } from 'models/EquipmentReturnConfig';
import { SparesRequestTypesList } from './SparesRequestTypesList';

interface Props {
  config?: EquipmentReturnConfiguration;
}

export const EditPageCards: React.FC<Props> = ({ config }: Props): JSX.Element => {
  return (
    <>
      <Row gutter={[8, 8]} style={{ marginBottom: 8 }}>
        <GeneralCard />
        <DispatcherNotificationsCard />
      </Row>
      <Row gutter={4}>
        <SparesRequestTypesList config={config} />
      </Row>
    </>
  );
};
