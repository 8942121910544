import { Row, Select, SelectProps } from 'antd';
import { Field, FieldProps } from 'formik';
import { CSSProperties, FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  type?: string;
  label: string;
  labelStyle?: CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
}

export const FieldInputSelect: FC<Props> = ({ fieldName, label, labelStyle, ...rest }) => {
  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        const handleChange = (val: string): void => {
          if (fieldName === 'countryCode') setFieldValue('subdivisionCode', '');

          setFieldValue(fieldName, val);
        };

        return (
          <Row style={{ width: '100%' }}>
            <FormLabel style={labelStyle} label={label} />

            <Select
              {...field}
              showSearch
              allowClear
              onClear={(): void => {
                setFieldTouched(fieldName, true);
              }}
              filterOption={(input, option): boolean => {
                const label = typeof option?.label === 'string' ? option.label.toLowerCase() : '';

                return label.toLowerCase().includes(input.toLowerCase()) ?? false;
              }}
              onChange={handleChange}
              status={meta.error && meta.touched ? 'error' : undefined}
              onFocus={(): void => setFieldTouched(fieldName, false)}
              onBlur={(): void => setFieldTouched(fieldName, true)}
              style={{ width: '100%' }}
              {...rest}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </Row>
        );
      }}
    </Field>
  );
};
