import { Input, Spin } from 'antd';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { ReduxState } from 'redux/store';
import { FormLabel } from './FormLabel';

interface Props {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
  reqName?: string;
}

export const FieldInputName: FC<Props> = ({ fieldName, placeholder, type, label, reqName }) => {
  const { handleSubmit } = useFormikContext();
  const { equipmentReturnTypesParams } = useSelector((state: ReduxState) => state);
  const { divisionId } = useParams();
  const { data: testTypes, isLoading, isFetching } = useGetEquipmentReturnConfigurationTypesQuery({ divisionId: divisionId as string, params: equipmentReturnTypesParams }, { skip: !divisionId });

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <Field name={fieldName} placeholder={placeholder}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta
        }: FieldProps): JSX.Element => {
          return (
            <div>
              <FormLabel label={label} />
              <Input onKeyDown={handleEnter} onFocus={() => setFieldTouched(fieldName, false)} style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }} type={type} {...field} />
              {/* <div className={handleError()}>{nameError ?? meta.error}</div> */}
            </div>
          );
        }}
      </Field>
    </Spin>
  );
};
