import { message, Space, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { CreateTypesControls } from 'components/molecules/CreateTypesControls';
import { TypesForm } from 'components/organisms/TypesForm';
import { FormikProvider, useFormik } from 'formik';
import { EquipmentReturnTypePayload } from 'models/EquipmentReturnTypes';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateEquipmentReturnConfigurationTypeMutation } from 'redux/services/maurice/sparingRequestConfigTypes';
import { setIsExit } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const CreateTypesPage = (): JSX.Element => {
  const { divisionId } = useParams();
  const navigate = useNavigate();
  const { isExit } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();

  const [triggerUpdate, { isLoading: isCreating }] = useCreateEquipmentReturnConfigurationTypeMutation();

  const formik2 = useFormik<EquipmentReturnTypePayload>({
    enableReinitialize: true,
    // validationSchema: requestTypePayload,
    initialValues: {
      description: '',
      isActive: true,
      name: '',
      allowManualAddressEntry: false,
      allowManualAddressToPostOffice: false,
      equipmentReturnRuleTypeName: '',
      displayAddressesByDefault: false,
      carrierAccountNumber: '',
      carrierName: '',
      warehouseRoutingPriorities: [],
      isDeleted: false
    },

    onSubmit: async (values) => {
      try {
        if (!divisionId) return message.error('Division cannot be found');
        const newType = await triggerUpdate({ divisionId, payload: values }).unwrap();

        message.success(`${newType.name} request type successfully created`);
        if (isExit) {
          navigate(`/${divisionId}/edit`);
          dispatch(setIsExit(false));
        } else {
          navigate(`/${divisionId}/types/${newType.id}/edit`);
        }
      } catch (err) {
        dispatch(setIsExit(false));
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  return (
    <FormikProvider value={formik2}>
      <Spin spinning={isCreating} indicator={<BlockLoader message="Creating equipment return type" direction="loader loader--slideUp" />}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <CreateTypesControls />
          <TypesForm />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
